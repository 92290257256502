import {createSlice} from '@reduxjs/toolkit';
export const loginSlice = createSlice({
    name :'Login',
    initialState : {
        isLogged : false,
        key : '',
        role:'',
        isAdmin:false,
        me  : {},
        details: {},
        id : ''
    },
    reducers : {
        loggin:(state,action)=>{
            state.isLogged = true;
            state.key = action.payload.accessToken;
            state.me = action.payload;
            state.details = action.payload?.detail?.details
            // state.role = action.payload.user.data.me.role
            state.isAdmin = action.payload?.isAdmin
            state.id= action?.payload?.id
        },
        logout : (state)=>{
            state = false
        }
    }
})
export const {loggin,logout} = loginSlice.actions;
export default loginSlice.reducer;